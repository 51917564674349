import { createRouter, createWebHistory } from "vue-router";
import { useCurrentStore } from "@/stores/current";
import adminRoutes from "./admin";
import BaseView from "@/views/BaseView.vue";
import { useAuthStore } from "@/stores/admin/auth";
import ChannelsView from "@/views/ChannelsView.vue";

const baseRoutes = [
    {
        path: "/itselettrica",
        redirect: () => {
            return { name: "home" };
        },
    },
    {
        path: "/itselettrica/:channelId",
        redirect: (to) => {
            return {
                name: "channel",
                params: { channelId: to.params.channelId },
            };
        },
    },
    {
        path: "",
        component: BaseView,
        children: [
            {
                path: "",
                component: ChannelsView,
                name: "home",
            },
            {
                path: ":channelId",
                component: () => import("@/views/ChannelView.vue"),
                name: "channel",
            },
            {
                path: ":channelId/listen",
                component: () => import("@/views/ListenView.vue"),
                name: "listen",
            },
        ],
    },
];

const adminPrefix = import.meta.env.VITE_ADMIN_PREFIX || "admin";
const routes =
    window.location.host.split(".")[0] === adminPrefix
        ? adminRoutes
        : baseRoutes;

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !useAuthStore().getUser) {
        return next({ name: "login", query: { redirect: to.fullPath } });
    }
    if (to.meta.requiresAuth && !useAuthStore().getUser.emailVerified) {
        return next({ name: "verify-email" });
    }
    if (to.meta.admin) {
        useCurrentStore().setAdmin(true);
    }
    if (to.meta.pollResults) {
        useCurrentStore().setPollResults(true);
    }
    return next();
});

export default router;
