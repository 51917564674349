<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useCurrentStore } from "@/stores/current";
import { useEventStore } from "@/stores/event";
import WarningPopup from "@cmp/WarningPopup.vue";
import ChannelSelect from "@cmp/ChannelSelect.vue";

const event = computed(() => useEventStore().getEvent);
const eventChannels = computed(() => event.value?.channels || []);

const router = useRouter();

const popup = ref(false);
const selectedChannelId = ref(null);

function openPopup() {
    popup.value = true;
    useCurrentStore().setPopupOpen(true);
}

function closePopup() {
    popup.value = false;
    useCurrentStore().setPopupOpen(false);
}

function goToChannel() {
    useCurrentStore().setPopupOpen(false);
    router.push({
        name: "listen",
        params: { channelId: selectedChannelId.value },
    });
}
</script>

<template>
    <div class="flex flex-col items-center mt-5">
        <WarningPopup
            v-if="popup"
            :button-text="$t('channels.popup.button')"
            :text="$t('channels.popup.body')"
            :title="$t('channels.popup.title')"
            type="warning"
            @close="closePopup"
            @next="goToChannel"
        />
        <div class="relative w-full flex flex-col px-5 max-w-xl">
            <p class="text-lg whitespace-pre-line mb-5">
                Ciao! <br />
                Seleziona un canale audio da ascoltare.
            </p>

            <channel-select
                :join-btn="true"
                :channels="eventChannels"
                v-model="selectedChannelId"
                @click="openPopup"
            ></channel-select>
        </div>
    </div>
</template>
