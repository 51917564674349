<script setup>
import { onMounted } from "vue";
import { refreshDarkMode } from "@utils/darkmode";
import { useEventStore } from "@/stores/event.js";

onMounted(() => {
    refreshDarkMode();
    useEventStore().setSlug("itselettrica");
});
</script>

<template>
    <router-view />
</template>
