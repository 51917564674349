<script setup>
const selectedChannelId = defineModel();

defineProps({
    joinBtn: {
        type: Boolean,
        default: false,
    },
    channels: {
        type: Array,
        default: () => [],
    },
});

defineEmits(["click"]);
</script>

<template>
    <div class="rounded-xl flex flex-col bg-gray-100 p-4">
        <label for="available-rooms" class="text-lg">
            Stanze disponibili:
        </label>
        <select
            id="available-rooms"
            class="rounded-md mt-1"
            v-model="selectedChannelId"
        >
            <option
                v-for="channel in channels"
                :key="channel.id"
                :value="channel.id"
            >
                {{ channel.name }}
            </option>
        </select>
        <button
            v-if="joinBtn"
            class="bg-green-700 text-white rounded-md h-10 font-bold text-lg mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
            @click="$emit('click')"
            :disabled="!selectedChannelId"
        >
            Entra
        </button>
    </div>
</template>
