import { defineStore } from "pinia";
import Janus from "@/services/janus";
import { useCurrentStore } from "@/stores/current";

const errorListeners = [];

export const useWebRTCStore = defineStore("webrtc", {
    state: () => ({
        url: null,
        janus: null,
        streaming: null,
        streams: [],
    }),
    actions: {
        async initialize(url) {
            if (url === this.url && this.janus) return;
            if (this.janus) {
                this.janus.destroy();
                this.janus = null;
                this.streaming = null;
                this.streams = [];
            }
            this.url = url;

            return new Promise((resolve, reject) => {
                if (this.janus) {
                    resolve();
                    return;
                }
                Janus.init({
                    debug: import.meta.env.VITE_JANUS_DEBUG,
                    callback: function () {
                        Janus.log(" ::: Got Janus :::");
                    },
                });
                initJanus(url, (error) => {
                    const audio = document.getElementById("remoteAudio");
                    if (audio) {
                        if (!audio.paused && audio.srcObject) audio.pause();
                        audio.srcObject = null;
                    }
                    this.stopStream();
                    useCurrentStore().setChannel(null);
                    this.janus = null;
                    this.streaming = null;
                    errorListeners.forEach((listener) => listener(error));
                    reject(error);
                })
                    .then(async (janus) => {
                        this.janus = janus;
                        if (!this.streaming)
                            this.streaming = await attachToStreamingPlugin(
                                janus,
                                async (msg, jsep) => {
                                    Janus.log(" ::: Got a message :::");
                                    Janus.log(msg);
                                    if (jsep) {
                                        Janus.debug(
                                            "Handling SDP as well...",
                                            jsep
                                        );
                                        // Offer from the plugin, let's answer
                                        await answerOffer(this.streaming, jsep);
                                    }
                                }
                            );
                        this.streams = await listStreams(this.streaming);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async playStream(id) {
            if (!this.janus || !this.isConnected) await this.initialize();
            if (!this.streaming) return;
            Janus.log("Selected stream id #" + id);
            this.streaming.send({
                message: {
                    request: "watch",
                    id: id,
                },
            });
        },
        async playStreamByRoomId(roomId) {
            let streamId = this.getStreamIdByRoomId(roomId);
            if (!streamId) {
                this.streams = await listStreams(this.streaming);
                streamId = this.getStreamIdByRoomId(roomId);
            }
            if (!streamId) throw new Error("Stream not found");
            await this.playStream(streamId);
            return streamId;
        },
        stopStream() {
            if (!this.streaming) return;
            this.streaming.send({
                message: {
                    request: "stop",
                },
            });
        },
        destroy() {
            if (this.janus) {
                this.janus.destroy();
                this.janus = null;
                this.streaming = null;
                this.streams = [];
            }
        },
        addErrorListener(listener) {
            errorListeners.push(listener);
        },
        removeErrorListener(listener) {
            const index = errorListeners.indexOf(listener);
            if (index !== -1) errorListeners.splice(index, 1);
        },
        removeAllErrorListeners() {
            errorListeners.splice(0, errorListeners.length);
        },
    },
    getters: {
        getStreamIdByRoomId: (state) => (roomId) => {
            return state.streams.find(
                (stream) => stream.description === "room-" + roomId
            )?.id;
        },
        isConnected: (state) => !!state.janus?.isConnected(),
    },
});

async function initJanus(url, onError) {
    return new Promise((resolve, reject) => {
        Janus.init({
            debug: import.meta.env.VITE_JANUS_DEBUG,
            callback: function () {
                Janus.log(" ::: Got Janus :::");
            },
        });
        let janus = new Janus({
            server: url,
            success: () => {
                resolve(janus);
            },
            error: function (error) {
                console.error("Janus connection error", error);
                onError(error);
                reject(error);
            },
        });
    });
}

function listStreams(streamingHandle) {
    return new Promise((resolve, reject) => {
        if (!streamingHandle) {
            reject("Streaming plugin not attached");
            return;
        }
        streamingHandle.send({
            message: { request: "list" },
            success: function (result) {
                if (!result?.list) {
                    resolve([]);
                    return;
                }
                Janus.log("Got a list of available streams");
                Janus.log(result["list"]);
                resolve(result["list"]);
            },
        });
    });
}

function attachToStreamingPlugin(janus, onMessageCallback) {
    return new Promise((resolve, reject) => {
        if (!janus) {
            reject("Janus not initialized");
            return;
        }
        janus.attach({
            plugin: "janus.plugin.streaming",
            opaqueId: "streamingtest-" + Janus.randomString(12),
            success: function (pluginHandle) {
                Janus.log(
                    "Plugin attached! (" +
                        pluginHandle.getPlugin() +
                        ", id=" +
                        pluginHandle.getId() +
                        ")"
                );
                resolve(pluginHandle);
            },
            error: function (error) {
                reject(error);
            },
            onmessage: onMessageCallback,
            onremotetrack: function (track, mid, on, metadata) {
                Janus.debug(
                    "Remote track (mid=" +
                        mid +
                        ") " +
                        (on ? "added" : "removed") +
                        (metadata ? " (" + metadata.reason + ") " : "") +
                        ":",
                    track
                );
                const audio = document.getElementById("remoteAudio");
                if (!audio) return;
                if (!audio.paused && audio.srcObject) audio.pause();
                audio.srcObject = null;
                if (!on) return;
                Janus.attachMediaStream(audio, new MediaStream([track]));
                audio.play();
            },
        });
    });
}

async function answerOffer(streamingHandle, jsepOffer) {
    let stereo = jsepOffer.sdp.indexOf("stereo=1") !== -1;
    const { body, jsep } = await new Promise((resolve, reject) => {
        streamingHandle.createAnswer({
            jsep: jsepOffer,
            // We only specify data channels here, as this way in
            // case they were offered we'll enable them. Since we
            // don't mention audio or video tracks, we autoaccept them
            // as recvonly (since we won't capture anything ourselves)
            tracks: [{ type: "data" }],
            customizeSdp: function (jsep) {
                if (stereo && jsep.sdp.indexOf("stereo=1") === -1) {
                    // Make sure that our offer contains stereo too
                    jsep.sdp = jsep.sdp.replace(
                        "useinbandfec=1",
                        "useinbandfec=1;stereo=1"
                    );
                }
            },
            success: function (jsep) {
                Janus.debug("Got SDP!", jsep);
                let body = {
                    request: "start",
                };
                resolve({
                    body,
                    jsep,
                });
            },
            error: function (error) {
                Janus.error("WebRTC error:", error);
                reject(error);
            },
        });
    });
    streamingHandle.send({
        message: body,
        jsep: jsep,
    });
}
